import React, { useState } from "react";
import moment from "moment";
import * as Yup from "yup";
import { useFormik, Field } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./HomeContact.css";
import { BiSolidPhoneCall } from "react-icons/bi";
import contactBg from "../../media/home-form.jpg";

const HomeContact = () => {
  const [loading, setLoading] = useState(false);
  const GOOGLE_SHEET_URL = "https://script.google.com/macros/s/AKfycbyBiKEUJ9I4clK9YD3Va6Kpu8yFNpvCq2IO6mxY8_7fbSDYqPWAYB9JBZkWoGh_tg2UgQ/exec";
  const url = window.location.href;
  const initialValues = {
    Name: "",
    Email: "",
    Mobile: "",
    typeofspace: "",
    city: "",
    location: "",
    url: url,
  };

  const phoneRegExp = /^[0-9]\d{9}$/;
  const validationSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Name is required"),
    Email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    Mobile: Yup.string()
      .matches(phoneRegExp, "Mobile is not valid")
      .required("Mobile is required"),
    typeofspace: Yup.string()
      .min(5, "Too Short!")
      .max(25, "Too Long!")
      .required("Required"),
    city: Yup.string()
      .min(5, "Too Short!")
      .max(25, "Too Long!")
      .required("Required"),
  });
  

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("Name", values.Name);
      formData.append("Email", values.Email);
      formData.append("Mobile", values.Mobile);
      formData.append("typeofspace", values.typeofspace);
      formData.append("city", values.city);
      formData.append("location", values.location);
      formData.append("url", values.url);
      formData.append("Date", moment().format("MMMM Do YYYY, h:mm:ss a")); 
      setLoading(true);
      await axios({
        method: "post",
        url: GOOGLE_SHEET_URL,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          // console.log("FORM_API_RES:::", response);
          // console.log("Formatted Date and Time: ", moment().format("MMMM Do YYYY, h:mm:ss a"));
          setLoading(false);
          toast.success("Thanks for contacting us.");
          resetForm();
        })
        .catch((error) => {
          setLoading(false);
          console.error("FORM_API_ERR:::", error);
          toast.error("Something went wrong.");
        });
    },
  });

  const { getFieldProps, handleSubmit, errors, touched } = formik;

  return (
    <>
      <ToastContainer style={{ zIndex: "99999" }} />
      <div className="container mob_hide">
        <div className="row">
          <div className="col-md-6">
            <div className="home_contact_bg">
              <LazyLoadImage
                src={contactBg}
                alt="homepage contact image"
                className="img-fluid"
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="home_contact_right">
              <h2 className="heading text-start">
                Best Properties with{" "}
                <span className="primary_color">Propularity</span>
              </h2>
              <p className="heading_text text-start">
                Let our experts help you to find best property
              </p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <input
                      type="text"
                      className="form-control modal_form_input home_input"
                      placeholder="Name*"
                      {...getFieldProps("Name")}
                    />
                    {errors.Name && touched.Name ? (
                      <span className="error_validate">{errors.Name}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 mb-4">
                    <input
                      type="email"
                      placeholder="Email*"
                      className="form-control modal_form_input home_input"
                      {...getFieldProps("Email")}
                    />
                    {errors.Email && touched.Email ? (
                      <span className="error_validate">{errors.Email}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 mb-4">
                    <input
                      type="text"
                      placeholder="Phone*"
                      className="form-control modal_form_input home_input"
                      {...getFieldProps("Mobile")}
                    />
                    {errors.Mobile && touched.Mobile ? (
                      <span className="error_validate">{errors.Mobile}</span>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-4">
                    <select
                      name="typeofspace"
                      className="home_select"
                      {...getFieldProps("typeofspace")}
                    >
                      <option defaultValue="I am looking for">I am looking for</option>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Collaboration">Collaboration</option>
                      <option value="Any Others">Any Others</option>
                    </select>
                  </div>
                  <div className="col-md-6 mb-4">
                    <select name="city" className="home_select" {...getFieldProps("city")}>
                      <option defaultValue="Select City">Select City</option>
                      <option value="Gurugram">Gurugram</option>
                      <option value="Mumbai">Mumbai</option>
                    </select>
                  </div>
                </div>
                <button className="modal_form_btn home_btn mb-4" type="submit">
                  {loading ? "Sending..." : "Submit"}
                </button>
              </form>
              <a href="tel:9999063322" className="footer_link">
                <BiSolidPhoneCall className="icon_call" /> Call Now : 9999063322
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeContact;
